import React from 'react';

import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import imgDictionary from './images.tsx';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";

function Photos() {
    const columnsCountBreakPoints = { 350: 1, 750: 2, 900: 3 };
    
    return (
        <div className='container'>
            <Accordion defaultActiveKey={['0']} alwaysOpen data-bs-theme="dark">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Current Time</Accordion.Header>
                    <Accordion.Body>
                        <div className='gallery-container'>
                            <ResponsiveMasonry>
                                <Masonry gutter={4}>
                                    {imgDictionary["first"].map((image) => (
                                        <img className='gallery-img' src={image} />
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Summer Trip 2024</Accordion.Header>
                    <Accordion.Body>
                        <div className='gallery-container'>
                            <ResponsiveMasonry>
                                <Masonry gutter={4}>
                                    {imgDictionary["s24trip"].map((image) => (
                                        <img className='gallery-img' src={image} />
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>School Photos</Accordion.Header>
                    <Accordion.Body>
                        <div className='gallery-container'>
                            <ResponsiveMasonry>
                                <Masonry gutter={4}>
                                    {imgDictionary["school"].map((image) => (
                                        <img className='gallery-img' src={image} />
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
        
    );
}

export default Photos;
