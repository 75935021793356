import school1 from "./pub/photo-album/school/school1.JPG";
import school2 from "./pub/photo-album/school/school2.JPG";
import school3 from "./pub/photo-album/school/school3.JPG";
import school4 from "./pub/photo-album/school/school4.JPG";
import school5 from "./pub/photo-album/school/school5.JPG";
import school6 from "./pub/photo-album/school/school6.JPG";
import school7 from "./pub/photo-album/school/school7.JPG";
import school8 from "./pub/photo-album/school/school8.JPG";
import school9 from "./pub/photo-album/school/school9.JPG";
import school10 from "./pub/photo-album/school/school10.JPG";
import school11 from "./pub/photo-album/school/school11.JPG";
import school12 from "./pub/photo-album/school/school12.JPG";
import school13 from "./pub/photo-album/school/school13.JPG";
import school14 from "./pub/photo-album/school/school14.JPG";
import school15 from "./pub/photo-album/school/school15.JPG";
import school16 from "./pub/photo-album/school/school16.JPG";
import school17 from "./pub/photo-album/school/school17.JPG";
import school18 from "./pub/photo-album/school/school18.JPG";
import school19 from "./pub/photo-album/school/school19.JPG";
import school20 from "./pub/photo-album/school/school20.JPG";
import school21 from "./pub/photo-album/school/school21.JPG";
import school22 from "./pub/photo-album/school/school22.JPG";
import school23 from "./pub/photo-album/school/school23.JPG";
import school24 from "./pub/photo-album/school/school24.JPG";
import school25 from "./pub/photo-album/school/school25.JPG";
import school26 from "./pub/photo-album/school/school26.JPG";
import school27 from "./pub/photo-album/school/school27.JPG";
import school28 from "./pub/photo-album/school/school28.JPG";
import school29 from "./pub/photo-album/school/school29.JPG";
import school30 from "./pub/photo-album/school/school30.JPG";
import school31 from "./pub/photo-album/school/school31.JPG";
import school32 from "./pub/photo-album/school/school32.JPG";
import school33 from "./pub/photo-album/school/school33.JPG";
import school34 from "./pub/photo-album/school/school34.JPG";
import school35 from "./pub/photo-album/school/school35.JPG";
import school36 from "./pub/photo-album/school/school36.JPG";
import school37 from "./pub/photo-album/school/school37.JPG";
import school38 from "./pub/photo-album/school/school38.JPG";
import school39 from "./pub/photo-album/school/school39.JPG";
import school40 from "./pub/photo-album/school/school40.JPG";
import school41 from "./pub/photo-album/school/school41.JPG";
import school42 from "./pub/photo-album/school/school42.JPG";
import s24trip1 from "./pub/photo-album/s24trip/s24trip1.JPG";
import s24trip2 from "./pub/photo-album/s24trip/s24trip2.JPG";
import s24trip3 from "./pub/photo-album/s24trip/s24trip3.JPG";
import s24trip4 from "./pub/photo-album/s24trip/s24trip4.JPG";
import s24trip5 from "./pub/photo-album/s24trip/s24trip5.JPG";
import s24trip6 from "./pub/photo-album/s24trip/s24trip6.JPG";
import s24trip7 from "./pub/photo-album/s24trip/s24trip7.JPG";
import s24trip8 from "./pub/photo-album/s24trip/s24trip8.JPG";
import s24trip9 from "./pub/photo-album/s24trip/s24trip9.JPG";
import s24trip10 from "./pub/photo-album/s24trip/s24trip10.JPG";
import s24trip11 from "./pub/photo-album/s24trip/s24trip11.JPG";
import s24trip12 from "./pub/photo-album/s24trip/s24trip12.JPG";
import s24trip13 from "./pub/photo-album/s24trip/s24trip13.JPG";
import s24trip14 from "./pub/photo-album/s24trip/s24trip14.JPG";
import s24trip15 from "./pub/photo-album/s24trip/s24trip15.JPG";
import s24trip16 from "./pub/photo-album/s24trip/s24trip16.JPG";
import s24trip17 from "./pub/photo-album/s24trip/s24trip17.JPG";
import s24trip18 from "./pub/photo-album/s24trip/s24trip18.JPG";
import s24trip19 from "./pub/photo-album/s24trip/s24trip19.JPG";
import s24trip20 from "./pub/photo-album/s24trip/s24trip20.JPG";
import s24trip21 from "./pub/photo-album/s24trip/s24trip21.JPG";
import s24trip22 from "./pub/photo-album/s24trip/s24trip22.JPG";
import s24trip23 from "./pub/photo-album/s24trip/s24trip23.JPG";
import s24trip24 from "./pub/photo-album/s24trip/s24trip24.JPG";
import s24trip25 from "./pub/photo-album/s24trip/s24trip25.JPG";
import s24trip26 from "./pub/photo-album/s24trip/s24trip26.JPG";
import s24trip27 from "./pub/photo-album/s24trip/s24trip27.JPG";
import first1 from "./pub/photo-album/first/first1.JPG";
import first2 from "./pub/photo-album/first/first2.JPG";
import first3 from "./pub/photo-album/first/first3.JPG";
import first4 from "./pub/photo-album/first/first4.JPG";
import first5 from "./pub/photo-album/first/first5.JPG";
import first6 from "./pub/photo-album/first/first6.JPG";
import first7 from "./pub/photo-album/first/first7.JPG";
import first8 from "./pub/photo-album/first/first8.JPG";
import first9 from "./pub/photo-album/first/first9.JPG";
import first10 from "./pub/photo-album/first/first10.JPG";
import first11 from "./pub/photo-album/first/first11.JPG";

let imgDictionary = {}
imgDictionary["first"] = [
   first11,
   first10,
   first9,
   first8,
   first7,
   first6,
   first5,
   first4,
   first3,
   first2,
   first1
]
imgDictionary["s24trip"] = [
   s24trip27,
   s24trip26,
   s24trip25,
   s24trip24,
   s24trip23,
   s24trip22,
   s24trip21,
   s24trip20,
   s24trip19,
   s24trip18,
   s24trip17,
   s24trip16,
   s24trip15,
   s24trip14,
   s24trip13,
   s24trip12,
   s24trip11,
   s24trip10,
   s24trip9,
   s24trip8,
   s24trip7,
   s24trip6,
   s24trip5,
   s24trip4,
   s24trip3,
   s24trip2,
   s24trip1
]
imgDictionary["school"] = [
   school42,
   school41,
   school40,
   school39,
   school38,
   school37,
   school36,
   school35,
   school34,
   school33,
   school32,
   school31,
   school30,
   school29,
   school28,
   school27,
   school26,
   school25,
   school24,
   school23,
   school22,
   school21,
   school20,
   school19,
   school18,
   school17,
   school16,
   school15,
   school14,
   school13,
   school12,
   school11,
   school10,
   school9,
   school8,
   school7,
   school6,
   school5,
   school4,
   school3,
   school2,
   school1
]

export default imgDictionary;
