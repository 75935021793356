import { useState } from 'react';
import logo from './pub/logo.png';
import li from './pub/in.png';
import git from './pub/giit.png';
import './App.css';
import Projects from './projects.tsx';
import 'bootstrap/dist/css/bootstrap.css';

function Home() {

  return (
    <div className="padded row">
    <div className="col-sm-4">
        
        <img src={logo} style={{padding: "1%",width: "80%", margin: "auto", display:"block"}} />
        <hr style={{width:"90%", margin: "auto"}} />
        <br />
        <h4>
          Hello, my name is Justin Templeton
        </h4>

    </div>
    <div className="col-sm-8 d-flex align-items-center">
        <span>
            <p className='padded'>
                <h4>
                    I have recently graduated from Iowa State University with a Bachelors in Cyber Security Engineering.
                    This website is to show some of my work and some formal documents like my resume with the bonus of hosting content on some of my hobbies.
                    The main one being the inclusion of my photo album on this website.
                </h4>
                <br></br>
                <a>
                  
                </a>
                <br></br>
                <h4>Thanks for taking a look!</h4>
            </p>
        </span>
    </div>
    
  </div>
  );
}

export default Home;
